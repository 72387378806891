export const GET_UPSELL_MENU_REQUEST = 'GET_UPSELL_MENU_REQUEST';
export const GET_UPSELL_MENU_SUCCESS = 'GET_UPSELL_MENU_SUCCESS';
export const GET_UPSELL_MENU_FAILURE = 'GET_UPSELL_MENU_FAILURE';

export const ADD_PRODUCTS_TO_UPSELL_MENU_DRAFT =
  'ADD_PRODUCTS_TO_UPSELL_MENU_DRAFT';
export const REMOVE_PRODUCT_FROM_UPSELL_MENU_DRAFT =
  'REMOVE_PRODUCT_FROM_UPSELL_MENU_DRAFT';

export const SORT_UPSELL_MENU_DRAFT = 'SORT_UPSELL_MENU_DRAFT';

export const EDIT_UPSELL_MENU_REQUEST = 'EDIT_UPSELL_MENU_REQUEST';
export const EDIT_UPSELL_MENU_SUCCESS = 'EDIT_UPSELL_MENU_SUCCESS';
export const EDIT_UPSELL_MENU_FAILURE = 'EDIT_UPSELL_MENU_FAILURE';

export const UNDO_UNSAVED_UPSELL_MENU_CHANGES =
  'UNDO_UNSAVED_UPSELL_MENU_CHANGES';

export const GET_CATEGORIES_UPSELL_MODAL = 'GET_CATEGORIES_UPSELL_MODAL';
export const GET_CATEGORIES_UPSELL_MODAL_REQUEST =
  'GET_CATEGORIES_UPSELL_MODAL_REQUEST';
export const GET_CATEGORIES_UPSELL_MODAL_FAILURE =
  'GET_CATEGORIES_UPSELL_MODAL_FAILURE';

export const GET_PRODUCTS_UPSELL_MODAL_REQUEST =
  'GET_PRODUCTS_UPSELL_MODAL_REQUEST';
export const GET_PRODUCTS_UPSELL_MODAL = 'GET_PRODUCTS_UPSELL_MODAL';
export const GET_PRODUCTS_UPSELL_MODAL_FAILURE =
  'GET_PRODUCTS_UPSELL_MODAL_FAILURE';

export const RESET_UPSELL_MODAL = 'RESET_UPSELL_MODAL';
