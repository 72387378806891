export const GET_BUSINESS_LOCATION_DETAILS_BY_BUSINESS_ID_REQUEST =
  'GET_BUSINESS_LOCATION_DETAILS_BY_BUSINESS_ID_REQUEST';
export const GET_BUSINESS_LOCATION_DETAILS_BY_BUSINESS_ID_SUCCESS =
  'GET_BUSINESS_LOCATION_DETAILS_BY_BUSINESS_ID_SUCCESS';
export const GET_BUSINESS_LOCATION_DETAILS_BY_BUSINESS_ID_FAILURE =
  'GET_BUSINESS_LOCATION_DETAILS_BY_BUSINESS_ID_FAILURE';
export const PATCH_BUSINESS_LOCATION_DETAILS_BY_BUSINESS_ID_REQUEST =
  'PATCH_BUSINESS_LOCATION_DETAILS_BY_BUSINESS_ID_REQUEST';
export const PATCH_BUSINESS_LOCATION_DETAILS_BY_BUSINESS_ID_SUCCESS =
  'PATCH_BUSINESS_LOCATION_DETAILS_BY_BUSINESS_ID_SUCCESS';
export const PATCH_BUSINESS_LOCATION_DETAILS_BY_BUSINESS_ID_FAILURE =
  'PATCH_BUSINESS_LOCATION_DETAILS_BY_BUSINESS_ID_FAILURE';
export const IS_DELETE_SPECIAL_HOURS_CONFIRM_MODAL_OPEN =
  'IS_DELETE_SPECIAL_HOURS_CONFIRM_MODAL_OPEN';
export const SPECIAL_HOURS_TO_DELETE = 'SPECIAL_HOURS_TO_DELETE';
export const DELETE_SPECIAL_HOURS_REQUEST = 'DELETE_SPECIAL_HOURS_REQUEST';
export const DELETE_SPECIAL_HOURS_SUCCESS = 'DELETE_SPECIAL_HOURS_SUCCESS';
export const IS_CREATE_AND_EDIT_SPECIAL_HOURS_MODAL_OPEN =
  'IS_CREATE_AND_EDIT_SPECIAL_HOURS_MODAL_OPEN';
export const SPECIAL_HOURS_TO_EDIT = 'SPECIAL_HOURS_TO_EDIT';
export const UPDATE_SPECIAL_HOURS_REQUEST = 'UPDATE_SPECIAL_HOURS_REQUEST';
export const UPDATE_SPECIAL_HOURS_SUCCESS = 'UPDATE_SPECIAL_HOURS_SUCCESS';
export const RESET_BUSINESS_LOCATION_DETAILS_REDUCER_STATE =
  'RESET_BUSINESS_LOCATION_DETAILS_REDUCER_STATE';
export const SET_BUSINESS_WIZARD_IS_VISIBLE = 'SET_BUSINESS_WIZARD_IS_VISIBLE'
export const CREATE_BUSINESS_LOCATION_REQUEST = 'CREATE_BUSINESS_LOCATION_REQUEST';
export const CREATE_BUSINESS_SUCCESS = 'CREATE_BUSINESS_SUCCESS';
export const POST_BUSINESS_ADDRESS_AND_DETAILS_SUCCESS = 'POST_BUSINESS_ADDRESS_AND_DETAILS_SUCCESS';
export const PUT_REGULAR_HOURS_SUCCESS = 'PUT_REGULAR_HOURS_SUCCESS';
export const INITIATE_CREATE_LOCATION = 'INITIATE_CREATE_LOCATION';
export const INITIATE_EDIT_LOCATION = 'INITIATE_EDIT_LOCATION';
export const UPDATE_BUSINESS_SUCCESS = 'UPDATE_BUSINESS_SUCCESS';
export const UPDATE_SPECIAL_HOURS_FAILURE = 'UPDATE_SPECIAL_HOURS_FAILURE';
export const PUT_REGULAR_HOURS_FAILURE = 'PUT_REGULAR_HOURS_FAILURE';
export const GET_PAYMENT_INTEGRATION_SCHEMA_SUCESS = 'GET_PAYMENT_INTEGRATION_SCHEMA_SUCESS';
export const GET_PAYMENT_INTEGRATION_SUCESS = 'GET_PAYMENT_INTEGRATION_SUCESS';
export const UPDATE_PAYMENT_INTEGRATION_SUCESS = 'UPDATE_PAYMENT_INTEGRATION_SUCESS';
