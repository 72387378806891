import React from "react";
import styled from "styled-components";
import loadingWhite from "../../../public/icons/loading_white.svg";
import loadingBlue from "../../../public/icons/loading_blue.svg";

const Img = styled.img`
  width: ${props => (props.large ? "30" : "16")}px;
  height: ${props => (props.large ? "30" : "16")}px;
  margin: ${props =>
    props.style ? props.style.margin && props.style.margin : "0 auto"};
`;

const LargeWrapper = styled.div`
  height: ${props => props.height || "200px"};
  display: flex;
  align-items: center;
`;

export const LoadingWhite = ({ large }) => (
  <Img src={loadingWhite} large={large} alt="" />
);

export const LoadingBlue = ({ large, style }) => (
  <Img src={loadingBlue} large={large} style={style} alt="" />
);

export const LoadingBlueLarge = ({ large, height, marginTop }) => (
  <LargeWrapper
    style={{ textAlign: "center", marginTop: marginTop || "60px" }}
    height={height}
  >
    <LoadingBlue large />
  </LargeWrapper>
);
