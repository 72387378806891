import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import ComboInformationFormContainer from './ComboInformationForm/containers/ComboInformationFormContainer';
import ComboItemsContainer from './ComboItems/containers/ComboItemsContainer';
import ComboAvailabilityContainer from './ComboAvailability/containers/ComboAvailabilityContainer';
import ComboBusinessesOverridesContainer from './ComboBusinessesOverridesForm/containers/ComboBusinessesOverridesContainer';

const ContentBody = styled.div`
  //width: 740px;
  margin: auto;
  display: grid;
  grid-gap: 24px;
`;

const CombosDetailIndex = ({ initValues, businesses }) => {
  return (
    <Fragment>
      <ContentBody>
        <ComboInformationFormContainer initValues={initValues} />
        <ComboItemsContainer initValues={initValues} />
        <ComboAvailabilityContainer
          initValues={initValues}
          businesses={businesses}
        />
        <ComboBusinessesOverridesContainer combo={initValues} />
      </ContentBody>
    </Fragment>
  );
};
export default CombosDetailIndex;
