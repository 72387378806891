import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as mediaActions from '../mediaActions';
import ImageForm from '../components/ImageForm';

class CreateImage extends Component {
  state = {
    file: null,
    name: '',
    altText: '',
    ready: false,
    croppedImageUrl: null,
    src: null,
    crop: {
      unit: "%",
        width: 30,
        aspect: null
    },
    editor: {
      open: (file, instructions) => {
        this.setState({ src: URL.createObjectURL(file) });
      },
      onconfirm: output => {
      },
      oncancel: () => {
        this.setState({ src: null, croppedImageUrl: null });
      },
      onclose: () => {
        this.setState({ src: null, croppedImageUrl: null });
      }
    }
  };

  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop : {
      ... percentCrop,
      aspect: null 
    }});
  };

  onChangeToIncompleteCropClick = (aspectRatio) => {
    this.setState({
      crop: {
        aspect: aspectRatio,
        unit: '%',
        width: 100,
      },
    });
  };
  
  makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      this.getCroppedImg(this.imageRef, crop, 'newFile.jpeg').then(croppedImageUrl =>
        this.setState({ croppedImageUrl })
      );
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }

  handleInit() {
    // console.log("FilePond instance has initialised", this.pond);
  }
  handleCropSubmit = () => {
    this.setState({
      files: this.state.croppedImageUrl,
      src: null,
      croppedImageUrl: null
    });
  };
  handleCropCancel = () => {
    this.setState({ src: null, croppedImageUrl: null });
  };

  handleImageUploadChange = files => {
    // there is image, take first one (array will only contain 1 element)
    if (files.length && files[0].fileSize / 1000 / 1000 <= 2) {
      this.setState({file: files[0].file});
    } else {
      this.setState({file: null});
    }
  };

  confirmReady = () => {
    this.setState({ready: true});
  };

  onSubmit = () => {
    if (this.state.file && this.state.name) {
      this.props.uploadImage(
        this.state.file,
        this.state.name,
        this.state.altText
      );
      this.setState({nameError: ''});
    }
    if (!this.state.name) {
      this.setState({nameError: 'Please name the image'});
    }
  };

  changeImageName = event => {
    const maxLength = 30;
    if (event.target.value.length <= maxLength) {
      this.setState({name: event.target.value});
    } else if (event.target.value.length > maxLength) {
      this.setState({
        nameError: `Image name can't be more than ${maxLength} characters`
      });
    }
    if (event.target.value.length < maxLength) {
      this.setState({nameError: ''});
    }
  };

  handleBeforeRemoveFile = () => {
    this.setState({ src: null, croppedImageUrl: null, name: '' });
  }

  handleOnUpadateFiles = (fileItems) => {
    this.setState({
      files: fileItems.map(fileItem => fileItem.file)
    });
  }

  handleOnAddFiles = (file) => {
    this.setState({ file: file.getMetadata() }, () =>
                      this.confirmReady()
                    )
    this.setState({name: file.file.name.replace(/\.([^.]*)$/, "").slice(0,30)})
  }

  changeAltText = event => {
    const maxLength = 255;
    if (event.target.value.length <= maxLength) {
      this.setState({altText: event.target.value});
    } else if (event.target.value.length > maxLength) {
      this.setState({
        altError: `Image alt text can't be more than ${maxLength} characters`
      });
    }
    if (event.target.value.length < maxLength) {
      this.setState({altError: ''});
    }
  };

  render() {
    const {hideCreateImageForm} = this.props;
    const submitIsDisabled =
      !this.state.name || !this.state.file || !this.state.ready;

    return (
      <ImageForm
        subTitle="Upload Image"
        subHeading="Upload new images to create custom URLs to be used throughout the
          portal (i.e. product images, home feed images, location images, etc)"
        cancelForm={hideCreateImageForm}
        submitIsDisabled={submitIsDisabled}
        changeImageName={this.changeImageName}
        nameError={this.state.nameError}
        altError={this.state.altError}
        changeAltText={this.changeAltText}
        handleImageUploadChange={this.handleImageUploadChange}
        confirmReady={this.confirmReady}
        formValues={this.state}
        onSubmit={this.onSubmit}
        submitButtonText="Create"
        files={this.state.files}
        handleInit={this.handleInit}
        src={this.state.src} 
        crop={this.state.crop}
        onImageLoaded={this.onImageLoaded}
        onCropComplete={this.onCropComplete}
        onChangeToIncompleteCropClick={this.onChangeToIncompleteCropClick}
        onCropChange={this.onCropChange}
        handleCropSubmit={this.handleCropSubmit}
        handleCropCancel={this.handleCropCancel}
        handleBeforeRemoveFile={this.handleBeforeRemoveFile}
        handleOnUpadateFiles={this.handleOnUpadateFiles}
        handleOnAddFiles={this.handleOnAddFiles}
        imageEditEditor={this.state.editor}



      />
    );
  }
}

export default connect(
  null,
  mediaActions
)(CreateImage);
