import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../productsActions';
import ProductVariantsForm from '../components/ProductVariantsForm';
import { selectFormattedVariants } from '../../../productsSelectors';

const ProductVariantsFormContainer = props => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [productVariantsAreEditable, setProductVariantsAreEditable] = useState(
    false
  );
  const [
    confirmCancelModalIsVisible,
    setConfirmCancelModalIsVisible
  ] = useState(false);

  useEffect(() => {
    if (!props.productVariantsInitialValues.length) {
      setProductVariantsAreEditable(true);
    }
  }, [props.productVariantsInitialValues]);

  return (
    <ProductVariantsForm
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      initialValues={{ productVariants: props.productVariantsInitialValues }}
      productVariantsAreEditable={productVariantsAreEditable}
      setProductVariantsAreEditable={setProductVariantsAreEditable}
      updateProductVariantsInitialValues={
        props.updateProductVariantsInitialValues
      }
      disabled={!props.productId}
      resetProductVariantsInitialValues={
        props.resetProductVariantsInitialValues
      }
      putProductVariants={props.putProductVariants}
      confirmCancelModalIsVisible={confirmCancelModalIsVisible}
      setConfirmCancelModalIsVisible={setConfirmCancelModalIsVisible}
      allVariants={props.allVariants}
      productId={props.productId}
      appConfig={props.appConfig ? props.appConfig.configuration : null}
      posMappingValues={props.posMappingValues}
      orderType={props.orderType}
    />
  );
};

const mapStateToProps = state => ({
  productId: state.productsReducer.productId,
  productVariantsInitialValues:
    state.productsReducer.productVariantsInitialValues,
  allVariants: selectFormattedVariants(state),
  appConfig: state.accountReducer.appMetaData,
  posMappingValues: state.posReducer.currentProductMappingValues,
  orderType: state.productsReducer.productInformationInitialValues.orderType
});

export default connect(mapStateToProps, actions)(ProductVariantsFormContainer);
